import _cloneDeep from 'lodash.clonedeep'
import { formatDate, formatTime, decodeKkmQrCodeString, decodeMinewQrCodeString } from '@smarttransit/common'

import {
  getArchivedOrUnarchivedAlternateIdentifiers,
  unregisterAlternateIdentifier,
  registerAlternateIdentifier
} from '../../../../../services/alternate-identifiers-service'

import { addAlert } from '../../../../../utilities/helpers'

export default {
  props: {
    signedInUser: Object,
    currentPassenger: Object,
    hasUserType: Function,
    alternateIdentifierTypes: Array,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      name: 'passengers-passenger-alternate-identifiers',
      alternateIdentifiers: [],
      currentAlternateIdentifier: null,
      currentAlternateIdentifierOriginal: null,
      registerApiInProgress: false,
      apiInProgress: false,
      saveApiInProgress: false,
      alternateIdentifierDialog: false,
      scannerLoading: false,
      detectedIdentifierQrCode: '',
      scannerFeedback: '',
      scannerErrorFeedback: ''
    }
  },
  watch: {
    alternateIdentifierDialog (val) {
      if (!val) {
        this.$router.replace({ query: undefined })
      }
    }
  },
  mounted () {
    this.getAlternateIdentifiers().then(() => {
      if (this.$route.query && this.$route.query.id) {
        this.loadAlternateIdentifierDialog(this.$route.query.id, true)
      }
    })
  },
  methods: {
    async onScannerLoaded (scannerPromise) {
      try {
        this.scannerLoading = true
        await scannerPromise
        // successfully initialized
      } catch (error) {
        console.error(error)
        let message = ''

        if (error.name === 'NotAllowedError') {
          message = 'Camera permission was denied, enable permissions in your browser settings and reload the page: '
        } else if (error.name === 'NotFoundError') {
          message = 'No suitable camera device installed: '
        } else if (error.name === 'NotSupportedError') {
          message = 'Page is not served over HTTPS: '
        } else if (error.name === 'NotReadableError') {
          message = 'Camera might already be in use: '
        } else if (error.name === 'OverconstrainedError') {
          message = 'Front camera seems to have been requested, but none detected: '
        } else if (error.name === 'StreamApiNotSupportedError') {
          message = 'Browser seems to be lacking features: '
        }

        alert(message + error.message)
      } finally {
        this.scannerLoading = false
      }
    },
    onScannerDecode (decodedContent) {
      try {
        this.detectedIdentifierQrCode = ''
        this.scannerErrorFeedback = ''

        if (decodedContent && (decodeMinewQrCodeString(decodedContent) || decodeKkmQrCodeString(decodedContent))) {
          const possibleIdentifierQrCode = decodeMinewQrCodeString(decodedContent) || decodeKkmQrCodeString(decodedContent)

          if (this.alternateIdentifiers.find((o) => (o.identifierKey === possibleIdentifierQrCode))) {
            alert('This identifier has already been registered')
          } else {
            this.detectedIdentifierQrCode = possibleIdentifierQrCode
          }
        } else {
          this.scannerFeedback = `Currently detected: ${decodedContent}`
        }
      } catch (error) {
        this.scannerErrorFeedback = error.message
      }
    },
    paintScannerBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 4
        ctx.strokeStyle = 'red'
        ctx.strokeRect(x, y, width, height)
      }
    },
    isNonValidPassenger () {
      return Boolean(this.currentPassenger.dateArchived || !this.currentPassenger.passengerIdentifier || !this.currentPassenger.passengerIdentifier.phoneNumber)
    },
    getAlternateIdentifiers () {
      this.apiInProgress = true

      return getArchivedOrUnarchivedAlternateIdentifiers({ passengerProfileId: this.$props.currentPassenger.id }).then((alternateIdentifiers) => {
        this.apiInProgress = false
        this.alternateIdentifiers = alternateIdentifiers.map((o) => (this.setAlternateIdentifier(o)))
        return this.alternateIdentifiers
      }).catch((err) => {
        this.apiInProgress = false
        addAlert({ message: err, type: 'error' })
      })
    },
    loadAlternateIdentifierDialog (alternateIdentifierId, loadedFromQuery) {
      if (Number(alternateIdentifierId) === 0) {
        if (!this.hasUserType('staff')) {
          return alert('No permission to register an alternate identifier')
        }

        if (!loadedFromQuery) {
          this.$router.replace({ query: { id: alternateIdentifierId } })
        }
        // load qr code reader to register new identifier
        this.currentAlternateIdentifier = { id: 0, identifierKey: null, metadata: null }
      } else {
        this.currentAlternateIdentifier = this.alternateIdentifiers.find((o) => (o.id === alternateIdentifierId))

        if (this.currentAlternateIdentifier) {
          this.currentAlternateIdentifier = this.setAlternateIdentifier(_cloneDeep(this.currentAlternateIdentifier))

          if (!loadedFromQuery) {
            this.$router.replace({ query: { id: alternateIdentifierId } })
          }
        }
      }

      if (!this.currentAlternateIdentifier) {
        return alert('No alternate identifier found with id: ' + alternateIdentifierId)
      }

      this.alternateIdentifierDialog = true
    },
    setAlternateIdentifier (alternateIdentifier) {
      if (alternateIdentifier.metadata) {
        alternateIdentifier.metadataLabel = JSON.stringify(alternateIdentifier.metadata, null, 2)
      }

      alternateIdentifier.identifierTypeLabel = (this.alternateIdentifierTypes.find((o) => (o.value === alternateIdentifier.identifierType)) || {}).text ||
        alternateIdentifier.identifierType
      alternateIdentifier.dateArchivedLabel = alternateIdentifier.dateArchived ? `${formatTime(alternateIdentifier.dateArchived)}, ${formatDate(alternateIdentifier.dateArchived)}` : ''
      alternateIdentifier.dateCreatedLabel = alternateIdentifier.dateCreated ? `${formatTime(alternateIdentifier.dateCreated)}, ${formatDate(alternateIdentifier.dateCreated)}` : ''
      alternateIdentifier.dateUpdatedLabel = alternateIdentifier.dateUpdated ? `${formatTime(alternateIdentifier.dateUpdated)}, ${formatDate(alternateIdentifier.dateUpdated)}` : ''

      return alternateIdentifier
    },
    async register () {
      if (this.hasUserType('staff')) {
        try {
          this.registerApiInProgress = true
          await registerAlternateIdentifier({ passengerProfileId: this.currentPassenger.id, identifierKey: this.detectedIdentifierQrCode })
          this.getAlternateIdentifiers()
          this.alternateIdentifierDialog = false
        } catch (error) {
          addAlert({ message: error, type: 'error', isModal: true })
        } finally {
          this.registerApiInProgress = false
        }
      }
    },
    unregister (item) {
      if (this.hasUserType('admin')) {
        const code = item.id.substring(item.id.length - 4)
        const promptResult = prompt(`Enter the code ${code} to confirm unregistering this identifier`)

        if (promptResult !== null) {
          if (promptResult !== code) {
            return alert('Incorrect code, please try again')
          }

          this.apiInProgress = true

          return unregisterAlternateIdentifier(item.id).then(() => {
            this.apiInProgress = false
            return this.getAlternateIdentifiers()
          }).catch((err) => {
            this.apiInProgress = false
            addAlert({ message: err, type: 'error' })
          })
        }
      }
    },
    cancelAlternateIdentifierDialog () {
      this.alternateIdentifierDialog = false
      this.currentAlternateIdentifier = null
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.currentAlternateIdentifier || !this.currentAlternateIdentifier.id === 0 ||
      (this.currentAlternateIdentifier.id === 0 && confirm('Exit registration?'))) {
      next()
    } else {
      next(false)
    }
  }
}

import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getAlternateIdentifier (id, filter) {
  return apiRequests.get('/v1/passenger-alternate-identifier/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getAlternateIdentifiers ({ passengerProfileId, filter }) {
  filter = {
    order: 'dateUpdated DESC',
    ...filter
  }

  if (passengerProfileId) {
    filter.where = filter.where || {}
    filter.where.passengerProfileId = passengerProfileId
  }

  return apiRequests.get('/v1/passenger-alternate-identifier/', {
    params: { filter: Object.keys(filter).length ? filter : undefined },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getArchivedOrUnarchivedAlternateIdentifier (id, filter) {
  return apiRequests.get('/v1/raw-passenger-alternate-identifier/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getArchivedOrUnarchivedAlternateIdentifiers ({ passengerProfileId, filter }) {
  filter = {
    order: 'dateUpdated DESC',
    ...filter
  }

  if (passengerProfileId) {
    filter.where = filter.where || {}
    filter.where.passengerProfileId = passengerProfileId
  }

  return apiRequests.get('/v1/raw-passenger-alternate-identifier/', {
    params: { filter: Object.keys(filter).length ? filter : undefined },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function unregisterAlternateIdentifier (id) {
  return apiRequests.delete('/v1/passenger-alternate-identifier/' + id + '/ble/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function registerAlternateIdentifier ({ passengerProfileId, identifierKey }) {
  return apiRequests.post('/v1/passenger-profile/' + passengerProfileId + '/passenger-alternate-identifier/ble/', {
    macAddress: identifierKey,
    metadata: { registeredVia: 'adminPortal' }
  }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getAlternateIdentifier,
  getAlternateIdentifiers,
  getArchivedOrUnarchivedAlternateIdentifier,
  getArchivedOrUnarchivedAlternateIdentifiers,
  unregisterAlternateIdentifier,
  registerAlternateIdentifier
}

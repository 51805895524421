<template>
  <div :class="name">
    <div v-if="$router.currentRoute.name === 'passengers-passenger-alternate-identifiers'">
      <h1 class="text-xs-center">Beacon / Tag Identifiers</h1>
      <br />
      <div v-if="currentPassenger">
        <v-alert :type="currentPassenger.dateArchived ? 'error' : 'info'" :value="true">
          <div class="layout justify-space-between align-center">
            <div>
              <h2>{{ currentPassenger.passengerIdentifier ? 'For &nbsp; ' + currentPassenger.passengerIdentifier.phoneNumber : '(No phone number registered)' }}</h2>
            </div>
            <div>
              <v-btn
                color="primary"
                @click="loadAlternateIdentifierDialog('0')"
                :disabled="!!(apiInProgress || currentPassenger.dateArchived || !currentPassenger.passengerIdentifier || !currentPassenger.passengerIdentifier.phoneNumber)">
                <v-icon>far fa-plus</v-icon> &nbsp; Register new identifier
              </v-btn>
            </div>
          </div>
        </v-alert>
        <div class="py-2">
          <div v-if="!alternateIdentifiers.length">
            <p class="text-xs-center">No identifiers available</p>
          </div>
          <v-card
            class="pointer"
            @click="loadAlternateIdentifierDialog(item.id)"
            v-for="(item, i) in alternateIdentifiers"
            :key="i"
            :class="'pa-2' + (item.dateArchived ? ' text--muted' : '')">
            <v-card-title primary-title>
              <div>
                <div class="headline">{{item.identifierKey}}</div>
                <div class="layout justify-start">
                  <div>
                    <div>Type: {{item.identifierTypeLabel}}</div>
                    <div v-show="item.dateArchived">Archived: {{item.dateArchivedLabel}}</div>
                    <div>Last updated: {{item.dateUpdatedLabel}}</div>
                    <div>Created: {{item.dateCreatedLabel}}</div>
                  </div>
                  <div class="ml-3 d-flex grow" style="overflow: auto; max-height: 100px;">
                    <pre>{{item.metadataLabel}}</pre>
                  </div>
                </div>
              </div>
            </v-card-title>
            <v-card-actions class="layout justify-end" v-show="!item.dateArchived && hasUserType('admin')">
              <v-btn @click.stop="unregister(item)">Unregister</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <v-dialog
          transition="dialog-bottom-transition"
          scrollable
          max-width="500"
          v-model="alternateIdentifierDialog">
        <v-card>
          <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
            <span v-show="currentAlternateIdentifier && currentAlternateIdentifier.id">Identifier Details</span>
            <span v-show="currentAlternateIdentifier && !currentAlternateIdentifier.id">Register Identifier</span>
          </v-card-title>
          <v-card-text>
            <div v-if="currentAlternateIdentifier && currentAlternateIdentifier.id">
              <div class="mb-2" style="max-width: 100%; overflow-x: auto"><code>{{currentAlternateIdentifier.identifierKey}}</code></div>
              <div>Type: {{currentAlternateIdentifier.identifierTypeLabel}}</div>
              <div v-show="currentAlternateIdentifier.dateArchived">Archived: {{currentAlternateIdentifier.dateArchivedLabel}}</div>
              <div>Last updated: {{currentAlternateIdentifier.dateUpdatedLabel}}</div>
              <div>Created: {{currentAlternateIdentifier.dateCreatedLabel}}</div>
              <div class="px-2 my-2" style="max-width: 100%; max-height: 100px; overflow: auto">
                <pre>{{currentAlternateIdentifier.metadataLabel}}</pre>
              </div>
            </div>
            <div v-if="currentAlternateIdentifier && !currentAlternateIdentifier.id">
              <qrcode-stream v-if="!detectedIdentifierQrCode" :track="paintScannerBoundingBox" @init="onScannerLoaded" @decode="onScannerDecode">
                <div v-if="scannerLoading" class="layout justify-center align-center" style="width: 100%; height: 100%;">
                  <v-progress-circular indeterminate :size="80" :width="6" color="primary" />
                </div>
              </qrcode-stream>
              <div v-if="detectedIdentifierQrCode" class="layout justify-center" style="width: 100%; height: 100%;">
                <div>
                  <h2 class="text-xs-center"><v-icon class="success--text">fas fa-check-circle</v-icon> {{detectedIdentifierQrCode}}</h2>
                  <div class="my-2">
                    <v-btn @click="detectedIdentifierQrCode = null" :disabled="registerApiInProgress">Scan again</v-btn> &nbsp;
                    <v-btn
                        color="primary"
                        :disabled="registerApiInProgress"
                        :loading="registerApiInProgress"
                        @click="register()">
                      Register this tag
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-alert v-if="!detectedIdentifierQrCode" :value="true" type="info" class="mb-2">
                {{scannerFeedback}}
              </v-alert>
              <v-alert v-if="!detectedIdentifierQrCode" :value="true" type="error" v-show="Boolean(scannerErrorFeedback)">
                {{scannerErrorFeedback}}
              </v-alert>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" flat :disabled="saveApiInProgress" @click="cancelAlternateIdentifierDialog">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
